/* eslint-disable react-hooks/exhaustive-deps */
import './LeafletPage.scss';

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {fabric} from 'fabric';
import {API_STATUSES} from 'config/api/constants';
import {frame} from 'shared/models/frame.model';
import {getFromLocalStorage} from 'utils/storageUtils';
import {
    buildImageObject,
    buildRectObject,
    CanvasFrame,
    colorFrame,
} from 'components/gfx/utils';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ColorPicker, {
    defaultFrameFillColor,
    frameFillLocalStorageKey,
    getComplementaryColor,
} from 'components/gfx/ColorPicker/ColorPicker';

import {
    strokeWidthSmall,
    strokeWidthLarge,
    resetAllMasks
} from 'components/gfx/FrameUtils';

const frameFillSelected: string = 'rgba(255,255,0,0)';

interface LeafletPageProps {
    externalImage: Blob,
    externalStatus: API_STATUSES,
    frames: frame[],
    fullScreen: boolean,
    hideColorPicker: boolean,
    isDisabled: boolean,
    frame2highlight?: string,
    onFrameClick: (frameId: string) => void
    onSwipeLeft: Function
    onSwipeRight: Function
}

const LeafletPage = (props: LeafletPageProps) => {
    const {externalImage, externalStatus, frames, fullScreen, hideColorPicker, isDisabled, frame2highlight, onFrameClick, onSwipeLeft, onSwipeRight} = props;

    const [image, setImage] = useState<Blob>(null);
    const [status, setStatus] = useState<API_STATUSES>(null);

    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [canvasReady, setCanvasReady] = useState<boolean>(false);
    const [isFrameInteractionDisabled, setIsFrameInteractionDisabled] = useState<boolean>(false);
    const isFrameInteractionDisabledRef = useRef(isFrameInteractionDisabled);

    let currentSelectedFrame: any = null;

    const setIsFrameInteractionDisabledRef = (value: boolean) => {
        isFrameInteractionDisabledRef.current = value;
        setIsFrameInteractionDisabled(value);
    };

    const handleFrameClick = (frameId: string) => {
        if (onFrameClick) {onFrameClick(frameId)}
    };

    const deselectFrame = () => {
        if (!isFrameInteractionDisabledRef.current) {
            const strokeColor: string = getComplementaryColor(frameFill);
            canvas.getObjects().forEach((frame: CanvasFrame) => {
                if (frame) {
                    colorFrame(frame, frameFill, strokeColor, strokeWidthSmall);
                }
            });
            resetAllMasks(canvas)
            handleFrameClick(null);
            canvas.renderAll();
        }
    }

    useEffect(() => {
        setIsFrameInteractionDisabledRef(isDisabled);
    }, [isDisabled])

    const handleEscKeyDown = (e: KeyboardEvent ) => (e.key === "Escape") && deselectFrame();

    const [frameFill, setFrameFill] = useState<string>(getFromLocalStorage(frameFillLocalStorageKey) || defaultFrameFillColor);
    const colorPicker = useRef(frameFill);
    const complementaryColor = useRef(getComplementaryColor(frameFill));

    useEffect(() => {
        // initialize canvas start
        const fabricCanvas = new fabric.Canvas('canvasId', {fireRightClick: true, stopContextMenu: true});
        setCanvas(fabricCanvas);

        if (fabricCanvas === null) {
            return;
        }

        setCanvasReady(true);
    }, []);

    useEffect(() => {
        if (canvasReady) {
            document.addEventListener('keydown', handleEscKeyDown);

            canvas.on('mouse:wheel', wheelZoom);

            canvas.on('mouse:down', function(o: {e: MouseEvent}) {
                let {e} = o;
                if (e.altKey || e.button === 2) {
                    this.isDragging = true;
                    this.selection = false;
                    this.lastPosX = e.clientX;
                    this.lastPosY = e.clientY;
                }
                if (e.button === 0) {
                    this.isSwiping = true;
                    this.selection = false;
                    this.lastPosX = e.clientX;
                    this.lastPosY = e.clientY;
                }
            });

            canvas.on('mouse:move', function(o: {e: MouseEvent}) {
                if (this.isDragging) {
                    let {e} = o;
                    let vpt = this.viewportTransform;
                    vpt[4] += e.clientX - this.lastPosX;
                    vpt[5] += e.clientY - this.lastPosY;
                    this.requestRenderAll();
                    this.lastPosX = e.clientX;
                    this.lastPosY = e.clientY;
                }
                if (this.isSwiping) {
                    let {e} = o;
                    if (e.clientX - 100 > this.lastPosX){
                        onSwipeRight();
                        this.isSwiping = false;
                    }
                    if (e.clientX + 100 < this.lastPosX){
                        onSwipeLeft();
                        this.isSwiping = false;
                    }
                }
            });

            canvas.on('mouse:up', function(e) {
                const strokeColor = getComplementaryColor(frameFill);
                if (this.isSwiping) {
                    if (onFrameClick && e?.target?.frameId && !isFrameInteractionDisabledRef.current) {
                        canvas.getObjects().forEach((frame: CanvasFrame) => {
                            if (frame) {
                                colorFrame(frame, frameFill, strokeColor, strokeWidthSmall);
                            }
                        });
                        const selectedFrame = canvas.getActiveObject();
                        currentSelectedFrame = selectedFrame;
                        // need to double-check if selectedFrame exist as sometimes its null
                        if (selectedFrame) {
                            canvas.getObjects().forEach((frame) => {
                                frame.set('strokeWidth', strokeWidthSmall)
                                frame.clipPath = new fabric.Rect({
                                    left: selectedFrame.left + strokeWidthLarge,
                                    top: selectedFrame.top + strokeWidthLarge,
                                    width: selectedFrame.width - strokeWidthLarge,
                                    height: selectedFrame.height - strokeWidthLarge,
                                    absolutePositioned: true,
                                    selectable: false,
                                    evented: false,
                                    inverted: true,
                                })
                            })
                            selectedFrame.set('fill', frameFillSelected);
                            selectedFrame.set('strokeWidth', strokeWidthLarge)
                            handleFrameClick(e.target.frameId);
                        }
                        canvas.renderAll();
                    } else {
                        deselectFrame();
                        if (currentSelectedFrame) canvas.setActiveObject(currentSelectedFrame);
                    }
                }
                this.setViewportTransform(this.viewportTransform);
                this.isDragging = false;
                this.isSwiping = false;
                this.selection = true;
            });
        }
        return () => document.removeEventListener('keydown', handleEscKeyDown);
    }, [canvasReady]);

    useEffect(() => {
        if (canvasReady) {
            const objs = canvas.getObjects();
            const strokeColor: string = getComplementaryColor(frameFill);
            if (objs) {

                objs.forEach((frame: CanvasFrame) => {
                    let frameFillColor: string = (frame.frameId === frame2highlight) ? frameFillSelected : frameFill;
                    let strokeWidth: number = (frame.frameId === frame2highlight) ? strokeWidthLarge : strokeWidthSmall;

                    colorFrame(frame, frameFillColor, strokeColor, strokeWidth);
                });

                const selectedFrame = canvas.getActiveObject();

                if (selectedFrame) {
                    selectedFrame.set('fill', frameFillSelected);
                    selectedFrame.set('strokeWidth', strokeWidthLarge)
                }
                setTimeout( () => canvas.renderAll(), 0);
            }
            colorPicker.current = frameFill;
            complementaryColor.current = strokeColor;
        }
    }, [canvasReady, frameFill]);

    const wheelZoom = (o: {e: WheelEvent}) => {
        const {e} = o;
        const delta = e.deltaY;
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom);
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (externalImage) {
            setImage(externalImage);
        }
    }, [externalImage]);

    useEffect(() => {
        if (externalStatus) {
            setStatus(externalStatus);
        }
    }, [externalStatus]);

    useEffect(() => {
        if (image) {
            const imgElement = document.getElementById(`leafletPageImage`) as HTMLImageElement;
            imgElement.src = window.URL.createObjectURL(new Blob([image], {type: "image/jpeg"}));

            const strokeColor: string = getComplementaryColor(frameFill);
            setTimeout(() => {
                canvas.setZoom(1);
                canvas.absolutePan({x: 0, y: 0}, 1);
                const imgElement = document.getElementById('leafletPageImage') as HTMLImageElement;
                const width: number = imgElement.naturalWidth;
                const height: number = imgElement.naturalHeight;
                const imgInstance = buildImageObject(imgElement, width, height);
                canvas.setBackgroundImage(imgInstance);
                canvas.remove(...canvas.getObjects());
                if (frames) {
                    let highlightedFrame: fabric.Rect = null;
                    frames.forEach((frame) => {
                        const rect = buildRectObject(frame, frameFill, strokeColor, strokeWidthSmall, true, true);
                        if (frame2highlight && rect.frameId === frame2highlight) {
                            highlightedFrame = rect;
                        }
                    });
                    if (highlightedFrame){
                        highlightedFrame.set('fill', frameFillSelected);
                        highlightedFrame.set('strokeWidth', strokeWidthLarge)
                        canvas.add(highlightedFrame);
                    }
                    frames.forEach((frame) => {
                        const rect = buildRectObject(frame, frameFill, strokeColor, strokeWidthSmall, true, true);
                        if (frame2highlight && rect.frameId === frame2highlight) {
                            rect.set('strokeWidth', strokeWidthLarge)
                        }
                        if (frame2highlight && highlightedFrame) {
                            rect.clipPath = new fabric.Rect({
                                left: highlightedFrame.left,
                                top: highlightedFrame.top,
                                width: highlightedFrame.width,
                                height: highlightedFrame.height,
                                absolutePositioned: true,
                                selectable: false,
                                evented: false,
                                inverted: true,
                            })
                        }
                        rect.reported = frame.reported;
                        canvas.add(rect);
                    });

                }
                canvas.setWidth(width);
                canvas.setHeight(height);

                const parentW: number = imgElement.parentElement.offsetWidth;
                const parentH: number = imgElement.parentElement.offsetHeight;

                const hRatio: number = parentW / width;
                const vRatio: number = parentH / height;
                const ratio: number = Math.min ( hRatio, vRatio );

                const centerShift_x: number = ( parentW - width * ratio ) / 2;
                const centerShift_y: number = ( parentH - height * ratio ) / 2;
                canvas.zoomToPoint({x: centerShift_x, y:centerShift_y}, ratio);

            }, 100)
        }
    }, [image, frames]);

    const handleColorChange = (value:string) => {
        colorPicker.current = value;
        complementaryColor.current = getComplementaryColor(value);
        setFrameFill(value);
    };

    return (
        <div className={fullScreen? 'leafletPageContainer canvasBackground' : 'leafletPageContainer'}>
            <img className={status !== API_STATUSES.IDLE ? 'hidden' : ''} id="leafletPageImage" alt="leafletPageImage" style={{"display": "none"}}/>
            <canvas ref={canvasRef} width="500px" height="500px" id='canvasId'/>
            <LoadingOverlay show={status === API_STATUSES.PENDING}/>
            {status === API_STATUSES.ERROR && <div className="errorMsg"><FormattedMessage id="leafletPage.error"/></div>}
            {!hideColorPicker && <ColorPicker isInsideCanvas value={frameFill} onChange={handleColorChange}/>}
        </div>
    );
};

export default LeafletPage;