import {useState} from 'react';
import {IconButton} from '@mui/material';
import {useSnackbar} from 'notistack';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DifferenceIcon from '@mui/icons-material/Difference';
import EditIcon from '@mui/icons-material/Edit';
import {DeleteOutline} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import {validity} from 'shared/models/validity.model';
import FrameValidityDialog from 'modules/Advertisement/FrameValidity/FrameValidityDialog';
import FrameValidity from 'modules/Advertisement/FrameValidity/FrameValidity';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {useFormatMessage} from 'utils/translate';

interface EditFrameValidityProps {
    disableDelete: boolean,
    disableEdit?: boolean,
    frameValidity: validity,
    onDelete: () => void,
    onSave: (frameValidity: validity) => void,
    showCopyTools: boolean,
    isCenteredOnRightSide?: boolean
}

const frameValidityCopyLocalStorage: string = 'clipboardFrameValidityFraming';

const EditFrameValidity = (props: EditFrameValidityProps) => {
    const {disableDelete, disableEdit, frameValidity, onDelete, onSave, showCopyTools, isCenteredOnRightSide} = props;
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const [open, setOpen] = useState<boolean>(false);

    const handleValiditySave = (frameValidity: validity) => {
        onSave(frameValidity);
        setOpen(false);
    }

    const handleCopyForm = () => {
        storeInLocalStorage(frameValidityCopyLocalStorage, JSON.stringify(frameValidity));
        enqueueSnackbar(`${translate({id: 'promotion.copied'})}`, {variant: 'info', persist: false});
    };
    
    const handlePasteForm = () => {
        const clipboardFrameValidity = JSON.parse(getFromLocalStorage(frameValidityCopyLocalStorage));
        if (clipboardFrameValidity) handleValiditySave(clipboardFrameValidity);
        else enqueueSnackbar(`${translate({id: 'promotion.copiedErr'})}`, {variant: 'error', persist: false});
    };
    return (
        <>
            <div className="_directionRow frameValidity">
                <FrameValidity frameValidity={frameValidity}/>
                <IconButton className="btn" 
                            onClick={() => setOpen(true)}
                            disabled={disableEdit}>
                    <Tooltip title={translate({id: 'a.edit'})}>
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                {showCopyTools && <><IconButton className="btn"
                            onClick={handleCopyForm}
                            disabled={!(frameValidity.validFrom && frameValidity.validTo)}>
                    <Tooltip title={translate({id: 'a.copy'})}>
                        <CopyAllIcon />
                    </Tooltip>
                </IconButton>
                <IconButton className="btn"
                            onClick={handlePasteForm}
                            disabled={!getFromLocalStorage(frameValidityCopyLocalStorage) || disableEdit}>
                    <Tooltip title={translate({id: 'a.paste'})}>
                        <DifferenceIcon />
                    </Tooltip>
                </IconButton></>}
                <IconButton className="btn"
                            onClick={onDelete}
                            disabled={disableDelete}>
                    <Tooltip title={translate({id: 'a.delete'})}>
                        <DeleteOutline />
                    </Tooltip>
                </IconButton>
            </div>
            <FrameValidityDialog open={open}
                                 onClose={() => setOpen(false)}
                                 onSave={(frameValidity) => handleValiditySave(frameValidity)}
                                 validity={frameValidity}
                                 isCenteredOnRightSide={isCenteredOnRightSide}
            />
        </>
    );
};

export default EditFrameValidity;