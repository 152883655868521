import './PromotionDrawer.scss';

import {FormattedMessage} from 'react-intl';
import {Button, Drawer} from '@mui/material';
import {promotionUI} from 'shared/models/promotion.model';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import Promotion from 'components/Promotion';
import {PaperX} from 'components/PaperX';
import {priceProposalModel} from 'shared/models/advertisement.model';

interface PromotionDrawerProps {
    country: string
    data: promotionUI,
    onCloneBPCO: (bpcoId: string, promotionUIId: string) => void,
    onCloneProduct: (productId: string, promotionUIId: string) => void,
    onClonePromotion: (promotionUIId: string) => void,
    onClose: () => void,
    onPromotionError: (error: boolean, UIId: string) => void,
    onRemovePromotion: (UIId: string) => void,
    onUpdatePromotion: (key: string, value: any, UIId: string) => void,
    open: boolean,
    priceProposal?: priceProposalModel
}

const PromotionDrawer = (props: PromotionDrawerProps) => {
    const {country, data, onCloneBPCO, onCloneProduct, onClonePromotion, onClose, onPromotionError, onRemovePromotion, onUpdatePromotion, open, priceProposal} = props;
    return (
        <Drawer className="frameDescriptionDrawerRoot promotionDrawerRoot"
                anchor="right"
                hideBackdrop
                open={open}
                onClose={onClose}
        >
            <div className="viewContainer _directionRow">
                <PaperX className="_fullHeight _fullWidth _scrollY">
                    {
                        data && country ? <Promotion countryId={country}
                                        data={data}
                                        key={`${data.UIId}_drawer`}
                                        onUpdatePromotion={onUpdatePromotion}
                                        onPromotionError={(error, promotionId) => onPromotionError(error, promotionId)}
                                        onCloneProduct={(productId, promotionUIId) => onCloneProduct(productId, promotionUIId)}
                                        onCloneBPCO={(bpcoId, promotionUIId) => onCloneBPCO(bpcoId, promotionUIId)}
                                        onClonePromotion={(promotionUIId) => onClonePromotion(promotionUIId)}
                                        priceProposal={priceProposal}
                                        detailsView={true}
                                />
                            :
                            null
                    }
                </PaperX>
            </div>
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        <Button className="removeBtn" color="primary" variant="outlined" size="small" tabIndex={-1}
                                onClick={() => onRemovePromotion(data.UIId)} >
                            <FormattedMessage id="a.remove"/>
                        </Button>
                        <ButtonClose onClick={onClose}/>
                    </>
                }
            />
        </Drawer>
    );
};

export default PromotionDrawer;