import './PromotionsTable.scss';
import 'components/Displays/Displays.scss';

import {ReactElement, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GetContextMenuItemsParams, MenuItemDef, RowClickedEvent} from 'ag-grid-community';
import {GridApi} from 'ag-grid-community/dist/lib/gridApi';
import {GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {handyLiterals} from 'shared/handyLiterals';
import {characteristicUI} from 'shared/models/product.model';
import {validity} from 'shared/models/validity.model';
import {PROMOTION_TYPES, promotionUI} from 'shared/models/promotion.model';
import {handyAttributes} from 'shared/handyAttributes';
import {useFormatMessage} from 'utils/translate';
import {storeInLocalStorage} from 'utils/storageUtils';
import AgTable, {localStorageColumnsKeys, selectAllColDef} from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import AttributesDisplay from 'components/Displays/AttributesDisplay';
import Attribute from 'components/Attribute';
import {promotionCopyLocalStorage} from 'components/Promotion/Promotion';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import GeneralCell from 'modules/Advertisement/AdvertisementsOverviewPromotions/GeneralCell';
import PriceCellBasic from './PriceCellBasic';
import PromotionsTableEditDrawer, {bulkPromoUpdatePayload} from './PromotionsTableEditDrawer';
import PromotionsTableEraseDrawer, {props2eraseType} from './PromotionsTableEraseDrawer';
import {capacitiesComparator} from 'utils/sortCapacities';
import {tradingUnitsComparator} from 'utils/sortTradingUnits';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import generalInformationValueGetter from 'utils/generalInformationValueGetter';
import {useSlimAttributeQuery} from 'components/Attribute/attributeAPI';
import {priceProposalObject} from 'shared/models/advertisement.model';

interface PromotionsTableProps {
    countryId: string
    forceEraseAndOverwrite: boolean,
    onBulkUpdate: (payload: bulkPromoUpdatePayload, promotionUIIids: string[], letItBreak?: boolean) => void,
    onCloneBPCO: (bpcoId: string, promotionUIId: string) => void,
    onCloneProduct: (productId: string, promotionUIId: string) => void,
    onClonePromotion: (promotionUIId: string) => void,
    onClonePromotionMultiple: (promotionUIIds: string[]) => void,
    onPasteFromLocalStorage: (promotionUIIds: string[], promotion: promotionUI) => void,
    onRemovePromotion: Function,
    onRemovePromotionMultiple: (promotionUIIds: string[]) => void,
    onRowClick: (id: string) => void,
    promotions: promotionUI[],
    priceProposals?: priceProposalObject
}

export interface TransformedPromotions extends promotionUI {
    generalInformation: {
        countryThemedWeeks: string[],
        promotionTypes: string[],
        themeSeasons: string[],
        qualitySeals: string[]
    },
    giveAwayBundling: string,
    mainAttributes: characteristicUI[],
    variety: string,
    contentOfTradingUnit: string,
    capacity: string,
    promotionValidityDates: string
}

const PromotionsTable = (props: PromotionsTableProps) => {
    const {countryId, forceEraseAndOverwrite, onBulkUpdate, onCloneBPCO, onCloneProduct, onClonePromotion, onClonePromotionMultiple,
        onPasteFromLocalStorage, onRemovePromotion, onRemovePromotionMultiple, onRowClick, promotions, priceProposals} = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const langData = useSelector((state: IRootState) => state.userProfile.langData);

    const promotionTypesDictionary = useSlimAttributeQuery(handyAttributes.promotionType, langData, true);
    const themeSeasonDictionary = useSlimAttributeQuery(handyAttributes.themeSeason, langData, true);
    const countryThemedWeeksDictionary = useSlimAttributeQuery(handyAttributes.countryThemedWeeks, langData, true);

    const gridApiRef = useRef<GridApi>();

    const [tableData, setTableData] = useState<TransformedPromotions[]>([]);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [eraseDrawerOpen, setEraseDrawerOpen] = useState<boolean>(false);
    const [confirmMultipleRemovalDialogOpen, setConfirmMultipleRemovalDialogOpen] = useState<boolean>(false);
    const [shouldEraseAndOverwrite, setShouldEraseAndOverwrite] = useState<boolean>(false);

    useEffect(() => {
        setTableData(transform4table(promotions));
        gridApiRef?.current?.getSelectedNodes()?.forEach((item) => item.setSelected(false));
    }, [promotions]);

    useEffect(() => {
        if (forceEraseAndOverwrite) handleForceBulkEraseAll();
    }, [forceEraseAndOverwrite]);

    const handlePasteForm = (promotionIUIIds: string[]) => {
        const clipboardPromotion = JSON.parse(localStorage.getItem(promotionCopyLocalStorage));
        if (promotionIUIIds?.length && clipboardPromotion) {
            onPasteFromLocalStorage(promotionIUIIds, clipboardPromotion);
        } else {
            enqueueSnackbar(`${translate({id: 'promotion.copiedErr'})}`, {variant: 'error', persist: false});
        }
    };

    const promotionsColDefs: ColDef[] = [
        { ...selectAllColDef, headerName: translate({id: 'a.select'}),
            headerTooltip: translate({ id: 'a.selectAll' }), sortable: false, pinned: 'left', resizable: false, lockPinned: true, width: 50, maxWidth: 50},
        { field: 'brand', headerName: translate({ id: 'b.brand' }), flex: 1, lockVisible: true },
        { field: 'description', headerName: translate({ id: 'b.descr' }) },
        { field: 'category', headerName: translate({id: 'b.category' }), flex: 2 },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            cellRenderer: (params) => <AttributesDisplay data={params.data.mainAttributes}/>
        },
        { field: 'model', headerName: translate({id: 'b.model' }), flex: 2 },
        { field: 'variety', headerName: translate({id: 'b.variety' }), flex: 2 },
        { field: 'contentOfTradingUnit', headerName: translate({id: 'b.contentOfTradingUnit' }), flex: 2,
            comparator: (a: TransformedPromotions['contentOfTradingUnit'], b: TransformedPromotions['contentOfTradingUnit']) => tradingUnitsComparator(a, b, langData)},
        { field: 'capacity', headerName: translate({id: 'b.capacity' }), flex: 2,
            comparator: (a: TransformedPromotions['capacity'], b: TransformedPromotions['capacity']) => capacitiesComparator(a, b, langData)},
        { field: 'priceCol', headerName: translate({ id: 'b.price' }), width: 240,
            cellRenderer: (params) => <PriceCellBasic data={params.data} priceProposal={priceProposals[params.data.UIId]}/>, valueGetter: (params) => params.data.promotionalPrice},
        {field: 'generalInformation', headerName: translate({ id: 'b.general' }),
            tooltipValueGetter: null,
            cellRenderer: (params) => <div className="generalInfoCell">
                                        <GeneralCellDerived data={params.data}/>
                                        <GeneralCell data={params.data.generalInformation}/>
                                      </div>,
            valueGetter: (params) => (generalInformationValueGetter(params, promotionTypesDictionary?.data, themeSeasonDictionary?.data, countryThemedWeeksDictionary?.data))},
        { field: 'promotionValidityDates', headerName: translate({ id: 'promotion.validity'}), flex: 2,
            tooltipField: 'promotionValidityDates'
        },
        { field: 'giveAwayBundling', headerName: translate({ id: 'productSearch.giveAwayBundling' }), flex: 2,
            tooltipField: 'giveAwayBundling'},
        { field: 'isTemplate', headerName: translate({ id: 'product.isTemplate' }),
            width: 160, cellClass: 'active-flag-cell',
            cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)
        }
    ];

    const defaultColDef = {
        resizable: true,
        suppressMenu: false,
        sortable: true
    };

    const getContextMenu = (rowNode: GetContextMenuItemsParams): (MenuItemDef | string)[] => {
        if (gridApiRef?.current?.getSelectedNodes()?.length) {
            return [
                {
                    name: translate({id: 'productSearch.clonePromotionSelected'}),
                    action: () => onClonePromotionMultiple(gridApiRef?.current?.getSelectedNodes().map((item) => item.data.UIId))
                },
                {
                    name: translate({id: 'a.pasteSelected'}),
                    action: () => handlePasteForm(gridApiRef?.current?.getSelectedNodes().map((item) => item.data.UIId))
                },
                {
                    name: translate({id: 'a.editSelected'}),
                    action: () => setEditDialogOpen(true)
                },
                'separator',
                {
                    name: translate({id: 'frameDescription.eraseAction'}),
                    action: () => setEraseDrawerOpen(true)
                },
                {
                    name: translate({id: 'frameDescription.eraseAndOverwriteAction'}),
                    action: () => handleForceBulkEraseAll()
                },
                'separator',
                {
                    name: translate({id: 'a.removeSelected'}),
                    action: () => setConfirmMultipleRemovalDialogOpen(true)
                }
            ];
        }
        // context menu is related only to rows, no table actions possible, disable context menu when clicking outside row without any rows selected
        if (rowNode.node === null) return [];

        return [
            {
                name: translate({id: 'productSearch.clonePromotion'}),
                action: () => onClonePromotion(rowNode.node.data.UIId)
            },
            {
                name: translate({id: 'a.copy'}),
                action: () => storeInLocalStorage(promotionCopyLocalStorage, JSON.stringify(rowNode.node.data))
            },
            {
                name: translate({id: 'a.paste'}),
                action: () => handlePasteForm([rowNode.node.data.UIId])
            },
            {
                name: rowNode.node.data.promotionObjectType === PROMOTION_TYPES.PRODUCT ? translate({id: 'product.createCopy'}) : translate({id: 'bpco.createCopy'}),
                action: () => rowNode.node.data.productId ? onCloneProduct(rowNode.node.data.productId, rowNode.node.data.UIId) : onCloneBPCO(rowNode.node.data.bpcoId, rowNode.node.data.UIId)
            },
            'separator',
            {
                name: translate({id: 'a.remove'}),
                action: () => onRemovePromotion(rowNode.node.data.UIId)
            }
        ];
    };

    const handleRowClicked = (e: RowClickedEvent) => {
        gridApiRef?.current?.getSelectedNodes()?.forEach((item) => item.setSelected(false));
        onRowClick(e.data.UIId )
    };

    const handleBulkUpdate = (payload: bulkPromoUpdatePayload, overwriteAll: boolean) => {
        const currentData: string[] = (gridApiRef?.current?.getSelectedNodes().length > 0)
            ? gridApiRef.current.getSelectedNodes().map((item) => item.data.UIId)
            : tableData.map((item) => item.UIId);
        if (overwriteAll) {
            handleBulkErase(null, true);
            onBulkUpdate(payload, currentData, true);
        }
        else onBulkUpdate(payload, gridApiRef?.current?.getSelectedNodes().map((item) => item.data.UIId));
        setShouldEraseAndOverwrite(false);
        setEditDialogOpen(false);
    };

    const handleConfirmMultipleRemoval = () => {
        onRemovePromotionMultiple(gridApiRef?.current?.getSelectedNodes().map((item) => item.data.UIId));
        setConfirmMultipleRemovalDialogOpen(false);
    };

    const handleBulkErase = (data: props2eraseType, forceEraseValues: boolean) => {
        const payload: bulkPromoUpdatePayload = {};
        const currentData: string[] = (gridApiRef?.current?.getSelectedNodes().length > 0)
            ? gridApiRef.current.getSelectedNodes().map((item) => item.data.UIId)
            : tableData.map((item) => item.UIId);

        if (data?.promotionalPrice || forceEraseValues) payload.promotionalPrice = null;
        if (data?.regularPrice || forceEraseValues) payload.regularPrice = null;
        if (data?.relativeDiscount || forceEraseValues) payload.relativeDiscount = null;
        if (data?.absoluteDiscount || forceEraseValues) payload.absoluteDiscount = null;
        if (data?.mpu || forceEraseValues) payload.mpu = 1;

        if (data?.promotionTypes || forceEraseValues) payload.promotionTypes = {id: handyAttributes.promotionType, literals: []};
        if (data?.themeSeasons || forceEraseValues) payload.themeSeasons = {id: handyAttributes.themeSeason, literals: []};
        if (data?.countryThemedWeeks || forceEraseValues) payload.countryThemedWeeks = {id: handyAttributes.countryThemedWeeks, literals: []};
        if (data?.qualitySeals || forceEraseValues) payload.qualitySeals = {id: handyAttributes.qualitySeal, literals: []};

        if (data?.validityDates || forceEraseValues) payload.validityDates = [];
        if (data?.giveAwayBundledProducts || forceEraseValues) payload.giveAwayBundledProducts = [];

        onBulkUpdate(payload, currentData, true);
        setEraseDrawerOpen(false);
    };

    const handleForceBulkEraseAll = () => {
        setShouldEraseAndOverwrite(true);
        setEditDialogOpen(true);
    }

    const handleClosePromotionsTableEditorDrawer = () => {
        setShouldEraseAndOverwrite(false);
        setEditDialogOpen(false);
    }

    return (
        <>
            <AgTable
                className="promotionsTableRoot"
                defaultColDef={defaultColDef}
                columnDefs={promotionsColDefs}
                enableBrowserTooltips={true}
                {...props}
                rowData={tableData}
                localStorageColumnsKey={localStorageColumnsKeys.promotionsTable}
                onRowClicked={(e: RowClickedEvent) => handleRowClicked(e)}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                suppressContextMenu={false}
                getContextMenuItems={getContextMenu}
                onGridReady={(e: GridReadyEvent) => gridApiRef.current = e.api}
            />
            <PromotionsTableEditDrawer countryId={countryId}
                                       data={tableData[0]}
                                       open={editDialogOpen}
                                       eraseAndOverwrite={shouldEraseAndOverwrite}
                                       onClose={handleClosePromotionsTableEditorDrawer}
                                       onConfirm={(data, shouldOverwrite) => handleBulkUpdate(data, shouldOverwrite)}
            />
            <PromotionsTableEraseDrawer data={gridApiRef?.current?.getSelectedNodes()?.map((item) => item.data)}
                                        open={eraseDrawerOpen}
                                        onClose={() => setEraseDrawerOpen(false)}
                                        onConfirm={(data) => handleBulkErase(data, false)}
            />
            <ConfirmDialog open={confirmMultipleRemovalDialogOpen}
                           onConfirm={handleConfirmMultipleRemoval}
                           onCancel={() => setConfirmMultipleRemovalDialogOpen(false)}
                           message={<FormattedMessage id="frameDescription.confirmSelectedRemoval" values={{count: gridApiRef?.current?.getSelectedNodes()?.length}}/>}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
            />
        </>
    );
};

const transform4table = (promotions: promotionUI[]): TransformedPromotions[] => {
    return promotions.map((item) => {
        const mainAttributes = item.characteristics.filter((ch) => ![handyLiterals.unidentified, handyLiterals.gpcUnidentified, handyLiterals.gpcUnclassified, handyLiterals.unclassified].includes(ch.valueId));
        const filteredMainAttributes = mainAttributes.filter(attr => ![handyAttributes.variety, handyAttributes.contentOfTradingUnit, handyAttributes.capacity].includes(attr.id));
        const variety = mainAttributes.filter(attr => attr.id === handyAttributes.variety)[0]?.value ?? '';
        const contentOfTradingUnit = mainAttributes.filter(attr => attr.id === handyAttributes.contentOfTradingUnit)[0]?.value ?? '';
        const capacity = mainAttributes.filter(attr => attr.id === handyAttributes.capacity)[0]?.value ?? '';
        const model = mainAttributes.filter(attr => attr.id === handyAttributes.model)[0]?.value ?? '';
        return {
        ...item,
        giveAwayBundling: item.giveAwayBundledProducts.map((ga) => ga.brand).join(', '),
        mainAttributes: filteredMainAttributes,
        model,
        variety,
        contentOfTradingUnit,
        capacity,
        promotionValidityDates: item.validityDates.map((vd) => dateStr(vd)).join('; '),
        generalInformation: {
            countryThemedWeeks: item.countryThemedWeeks.literals.map((ctw) => ctw.literalName),
            promotionTypes: item.promotionTypes.literals.map((pt) => pt.literalName),
            themeSeasons: item.themeSeasons.literals.map((ts) => ts.literalName),
            qualitySeals: item.qualitySeals.literals.map((qs) => qs.literalName)
        }
    }});
};

const dateStr = (validity: validity): string => {
    if (validity.validFrom !== validity.validTo) {
        return `${validity.validFrom} - ${validity.validTo}`
    }
    return validity.validFrom;
}

interface GeneralInformationCellWithDerivedProps {
    data: TransformedPromotions
}
const GeneralCellDerived = ({data}: GeneralInformationCellWithDerivedProps) => {
    let dpt: ReactElement = null;
    if (!data.promotionTypes?.literals?.length && data.derivedPromotionTypes?.literals?.length )
        dpt = <div className="displayChip derived"><Attribute id={handyAttributes.promotionType} data={data.derivedPromotionTypes} multiselect={true} textmode={true}/></div>;
    let dts: ReactElement = null;
    if (!data.themeSeasons?.literals?.length && data.derivedThemeSeasons?.literals?.length )
        dts = <div className="displayChip derived"><Attribute id={handyAttributes.themeSeason} data={data.derivedThemeSeasons} multiselect={true} textmode={true}/></div>;
    let dctw: ReactElement = null;
    if (!data.countryThemedWeeks?.literals?.length && data.derivedThemedWeeks?.literals?.length )
        dctw = <div className="displayChip derived"><Attribute id={handyAttributes.countryThemedWeeks} data={data.derivedThemedWeeks} multiselect={true} textmode={true}/></div>;
    return (
        <>
            {dpt}
            {dts}
            {dctw}
        </>
    );
};

export default PromotionsTable;