import 'components/Displays/PriceDisplay.scss';

import {FormattedMessage} from 'react-intl/lib';
import {useFormatMessage} from 'utils/translate';
import {TransformedPromotions} from './PromotionsTable';
import {priceProposalModel} from 'shared/models/advertisement.model';

interface PriceCellBasicProps {
    data: TransformedPromotions,
    priceProposal?: priceProposalModel
}
const PriceCellBasic = ({data, priceProposal}: PriceCellBasicProps) => {
    const translate = useFormatMessage();

    return (
        <div className="priceDisplayRoot">
            {data.promotionalPrice ? <span className={`displayChip ${priceProposal?.promotionalPrice ? "priceProposalChip" : "priceChip2"}`} title={translate({id: 'b.promotionalPrice'})}>{data.promotionalPrice} {data.currency}</span> : null}
            {data.regularPrice ? <span className={`displayChip ${priceProposal?.regularPrice ? "priceProposalChip" : "priceChip"}`} title={translate({id: 'b.regularPrice'})}>{data.regularPrice} {data.currency}</span> : null}
            {data.relativeDiscount ? <span className={`displayChip ${priceProposal?.relativeDiscount ? "priceProposalChip" : "priceChip"}`} title={translate({id: 'b.relativeDiscount'})}>{data.relativeDiscount} %</span> : null}
            {data.absoluteDiscount ? <span className={`displayChip ${priceProposal?.absoluteDiscount ? "priceProposalChip" : "priceChip"}`} title={translate({id: 'b.absoluteDiscount'})}>{data.absoluteDiscount}</span> : null}
            <span className="displayChip priceChip"><FormattedMessage id="b.MPU"/>: {data.mpu}</span>
        </div>
    );
};
export default PriceCellBasic;