import './FrameValidityDialog.scss';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import ButtonClose from "components/Buttons/ButtonClose";
import {useFormatMessage} from "utils/translate";
import {DatePicker} from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import {useEffect, useState} from "react";
import {isBefore} from "date-fns";
import {frameValidityTemplate} from "./FrameValidity";
import {dateFormat} from 'shared/models/validity.model';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

interface frameValidityDialogProps {
    open: boolean,
    onClose: () => void,
    onSave: (frameValidity: frameValidityTemplate) => void,
    validity?: frameValidityTemplate,
    isCenteredOnRightSide?: boolean
}

type frameValidityDef = {
    validFrom: Date,
    validTo: Date
};

const FrameValidityDialog = (props:frameValidityDialogProps) => {
    const translate = useFormatMessage();
    const dateFns = new AdapterDateFns();
    const {onClose, onSave, open, validity, isCenteredOnRightSide} = props;
    const [frameValidity, setFrameValidity] = useState<frameValidityDef>({validFrom: null, validTo: null});

    useEffect(() => {
        if (open && validity){
            const convertedValidity:frameValidityDef = {validFrom: null, validTo: null};
            convertedValidity.validFrom = validity.validFrom ? new Date(validity.validFrom) : null;
            convertedValidity.validTo = validity.validTo ? new Date(validity.validTo) : null;
            setFrameValidity(convertedValidity);
        } else setFrameValidity({validFrom: null, validTo: null});
    },[validity, open])

    const canSave = () => !(!frameValidity.validFrom || !frameValidity.validTo || isBefore(frameValidity.validTo , frameValidity.validFrom));

    const validateFrom = (e):boolean => {
        return e || isBefore(frameValidity.validTo , frameValidity.validFrom);
    };

    const validateTo = (e):boolean => {
        return e || isBefore(frameValidity.validTo, frameValidity.validFrom);
    };

    const handleValidityChange = (key, value) => {
        const newValidity = {...frameValidity};
        newValidity[key] = value;
        setFrameValidity(newValidity);
    };

    const handleSaveValidity = () => {
        const stringValidity:frameValidityTemplate = {validFrom: null, validTo: null};
        stringValidity.validFrom = dateFns.formatByString(frameValidity.validFrom, dateFormat);
        stringValidity.validTo = dateFns.formatByString(frameValidity.validTo, dateFormat);
        onSave(stringValidity);
    };

    return (
        <Dialog className={`frameValidityDialogRoot ${isCenteredOnRightSide ? 'centeredRight' : ''}`.trim()} open={open} maxWidth={'xs'} fullWidth>
            <DialogTitle>
                <FormattedMessage id="frameDescription.addFrameValidity"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <div className="datepickerRoot bottom">
                    <DatePicker
                        clearable
                        clearText={translate({id: 'a.clear'})}
                        label={translate({id: 'frame.validFrom'})}
                        value={frameValidity.validFrom}
                        onChange={date => handleValidityChange("validFrom", date)}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                            <>
                                <TextField {...params} error={validateFrom(params.error)} required={true}/>
                                <IconButton className="clearBtn" onClick={() => handleValidityChange("validFrom", null)}>
                                    <ClearIcon />
                                </IconButton>
                            </>
                        )}
                    />
                </div>
                <div className="datepickerRoot">
                    <DatePicker
                        clearable
                        clearText={translate({id: 'a.clear'})}
                        label={translate({id: 'frame.validTo'})}
                        value={frameValidity.validTo}
                        onChange={date => handleValidityChange("validTo", date)}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                            <>
                                <TextField {...params} error={validateTo(params.error)} required={true}/>
                                <IconButton className="clearBtn" onClick={() => handleValidityChange("validTo", null)}>
                                    <ClearIcon />
                                </IconButton>
                            </>
                        )}
                    />
                </div>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={() => handleSaveValidity()} variant={"contained"} disabled={!canSave()}>
                    <FormattedMessage id="a.save"/>
                </Button>
                <ButtonClose onClick={onClose} />
            </DialogActions>
        </Dialog>
    );
};

export default FrameValidityDialog;