import './OverviewFilters.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';
import {isBefore} from 'date-fns';
import {Checkbox, FormControl, InputLabel, ListItemIcon, MenuItem, Select} from '@mui/material';
import {mediumTypeTranslationsPrintMedia, mediumType, leafletStatus} from 'shared/models/leaflet.model';
import {IRootState} from 'shared/reducers';
import {handyAttributes} from 'shared/handyAttributes';
import {enumAttributeDataModel, multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {bannersWithRetailerResponseModel, countryAndBanner} from 'shared/models/banner.model';
import {filterObject} from 'shared/models/filterComponent.model';
import {isBannerInCountry} from 'shared/reducers/bannersWithRetailer';
import {countryMarketItem} from 'shared/reducers/userProfile';
import {useFormatMessage} from 'utils/translate';
import {storeInLocalStorage, getFromLocalStorage} from 'utils/storageUtils';
import {PaperX} from 'components/PaperX';
import {Attribute} from 'components/Attribute';
import {LeafletRepresentativeBranchSelector, ValiditySelector} from 'components/Selectors';
import {NumberInput} from 'components/Inputs';
import {valueTypes} from 'components/Inputs/NumberInput';
import {dateToString} from 'modules/Leaflet/HeaderData/PageValidity/pageValiditiesIO';
import {repBranch} from 'components/Selectors/LeafletRepresentativeBranchSelector/LeafletRepresentativeBranchSelector';
import CountryBannerSelectorConnected from 'components/Selectors/CountryBannerSelector/CountryBannerSelectorConnected';
import {languages} from 'modules/Dictionaries/Dictionary';
import MediumTypeSelector from 'components/Selectors/MediumTypeSelector/MediumTypeSelector';

interface overviewFiltersProps {
  onFilterChange: (overviewFilters: overviewFilters) => void,
  view: string;
}

export type overviewFilters = {
  status?: leafletStatus[],
  bannerId?: string,
  validFrom?: string,
  validTo?: string,
  leafletTypeId?: enumAttributeDataModel[],
  numberOfPages?: number,
  mediumType?: mediumType[],
  representativeBranchId?: repBranch,
  language: string[],
  country: string
}

const STATUS_ALL = 'ALL';

const leafletStatusLabels = {
  'ALL': 'a.selectAll',
  [leafletStatus.UPLOADED]: 'printMediaOverview.leafletUploaded',
  [leafletStatus.PAGED]: 'printMediaOverview.leafletPaged',
  [leafletStatus.ENTERED_HEADER_DATA]: 'printMediaOverview.leafletDescribed',
  [leafletStatus.FRAMED]: 'printMediaOverview.leafletFramed',
  [leafletStatus.DESCRIBING_IN_PROGRESS]: 'leafletStatus.desribingInProgress',
  [leafletStatus.FRAMES_DESCRIBED]: 'leafletStatus.done'
}

export const status: string[] = [
  'UPLOADED',
  'PAGED',
  'ENTERED_HEADER_DATA',
  'FRAMED',
  'DESCRIBING_IN_PROGRESS',
  'FRAMES_DESCRIBED'
]

const printMediaOverviewFiltersStorageKey: string = 'filters_printMediaOverview';
const advertisementOverviewFiltersStorageKey: string = 'filters_advertisementOverview_v3';

export const overviewFilterTemplate: overviewFilters = {
  status: [],
  validFrom: null,
  validTo: null,
  leafletTypeId: [],
  mediumType: [],
  numberOfPages: null,
  representativeBranchId: null,
  language: [],
  country: null
}

export const tryDate2String = (date: Date): string => {
  let result = null;
  try {
    result = dateToString(date);
  } catch (e) {}
  return result;
}

function sanitizeBannerVsCountry (filters: overviewFilters, bannersData: bannersWithRetailerResponseModel): overviewFilters {
  if (filters.bannerId) {
    if (isBannerInCountry(filters.bannerId, filters.country, bannersData)) {
      return filters;
    }
    return {
      ...filters,
      bannerId: null,
      representativeBranchId: null
    };
  }
  return filters;
}

function clearBannerIdIfCountryHasChangedOutside (filters: overviewFilters, countryMarket: countryMarketItem): overviewFilters {
  if (filters) {
    if (filters.country !== countryMarket.preferredCountry) {
      return {...filters, bannerId: null};
    }
    return filters;
  }
    return null;
}

const mediumTypes: mediumType[] = Object.keys(mediumTypeTranslationsPrintMedia).map(k => mediumType[k]);

export default function OverviewFilters(props:overviewFiltersProps) {
  const translate = useFormatMessage();

  const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);
  const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

  const storageKey: string = props.view === 'leaflet' ? printMediaOverviewFiltersStorageKey : advertisementOverviewFiltersStorageKey;
  const storedFilters: overviewFilters = clearBannerIdIfCountryHasChangedOutside(JSON.parse(getFromLocalStorage(storageKey)), countryMarket);

  const [statusFilters, setStatusFilters] = useState<leafletStatus[]>(storedFilters?.status ? storedFilters.status : [])
  const [selectedMediumType, setSelectedMediumType] = useState<mediumType[]>(storedFilters?.mediumType ? storedFilters.mediumType : []);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(storedFilters?.language ? storedFilters.language : [])
  const [filters, setFilters] = useState<overviewFilters>(storedFilters ? {...storedFilters, country: countryMarket.preferredCountry} : overviewFilterTemplate);
  const [prevFilters, setPrevFilters] = useState<overviewFilters>(storedFilters ? {...storedFilters, country: countryMarket.preferredCountry} : overviewFilterTemplate);
  const [leafletType, setLeafletType] = useState<multiselectAttributeDataModel>({id: handyAttributes.leafletType, literals: storedFilters?.leafletTypeId ? storedFilters.leafletTypeId : []});
  const [validFrom, setValidFrom] = useState<Date>(storedFilters?.validFrom ? new Date(storedFilters.validFrom) : null);
  const [validTo, setValidTo] = useState<Date>(storedFilters?.validTo ? new Date(storedFilters.validTo) : null);

  const initialFiltersRef = useRef<overviewFilters>(storedFilters ? {...storedFilters, country: countryMarket.preferredCountry} : overviewFilterTemplate);
  const initialFiltersChanged: boolean = JSON.stringify(filters) === JSON.stringify(initialFiltersRef.current);

  useEffect(() => {
    if (overviewFilterTemplate.country === null) overviewFilterTemplate.country = countryMarket.preferredCountry;
  }, [overviewFilterTemplate]);

  useEffect(() => {
    if (leafletType.literals.length) {
      setFilters({...filters, leafletTypeId: leafletType.literals})
    } else {
      setFilters({...filters, leafletTypeId: null})
    }
  }, [leafletType]);

  useEffect(() => {
    setFilters({...filters, status: statusFilters});
  }, [statusFilters]);

  useEffect(() => {
    setFilters({...filters, mediumType: selectedMediumType});
  }, [selectedMediumType]);

  useEffect(() => {
    setFilters({...filters, language: selectedLanguages});
  }, [selectedLanguages])

  useEffect(() => {
    if (initialFiltersChanged && bannersData.length) {
      props.onFilterChange(sanitizeBannerVsCountry(filters, bannersData));
    }
  }, [bannersData]);

  useEffect(() => {
    storeInLocalStorage(storageKey, JSON.stringify(filters));
    if (!initialFiltersChanged) props.onFilterChange(sanitizeBannerVsCountry(filters, bannersData));
  }, []);

  useEffect(() => {
    storeInLocalStorage(storageKey, JSON.stringify(filters));
    if (!initialFiltersChanged && JSON.stringify(filters) !== JSON.stringify(prevFilters) ) {
      props.onFilterChange(sanitizeBannerVsCountry(filters, bannersData));
      setPrevFilters(filters);
    }
  }, [filters]);

  const handleStatusChange = (statuses: leafletStatus[] | string) => { // shortest way to handle fact that SelectChange event impose string-type value
    if (Array.isArray(statuses)) {
      if (statuses[statuses.length -1] === STATUS_ALL) {
        setStatusFilters(statusFilters.length === status.length ? [] : mapOfLeafletStatuses);
        return;
      }
      setStatusFilters(statuses);
    }
  }

  const handleMediumTypeChange = (e) => {
    const selectedMediumTypes: mediumType[] = e.target.value;
    setSelectedMediumType(selectedMediumTypes);
  };

  const mapOfLeafletStatuses = [leafletStatus.UPLOADED, leafletStatus.PAGED, leafletStatus.ENTERED_HEADER_DATA, leafletStatus.FRAMED, leafletStatus.DESCRIBING_IN_PROGRESS, leafletStatus.FRAMES_DESCRIBED];

  const handleLanguageChange = (value: string[] | string) => { // shortest way to handle fact that SelectChange event impose string-type value
    if (Array.isArray(value)) {
      if (value[value.length -1] === STATUS_ALL) {
        setSelectedLanguages(selectedLanguages.length === languages.length ? [] : languages);
        return;
      }
      setSelectedLanguages(value);
    }
  };

  const handleCountryBannerChange = (countryBanner: countryAndBanner) => {
    setFilters({
      ...filters,
      bannerId: countryBanner.banner || null,
      country: countryBanner.country,
      representativeBranchId: null
    });
  };

  const handleNumberOfPagesChange = (numberOfPages: number) => {
    if (numberOfPages > 0) setFilters({...filters, numberOfPages});
    else if (filters.numberOfPages !== null) setFilters({...filters, numberOfPages: null});
  }
  
  const handleRepresentativeBranchChange = (representativeBranch: repBranch) => setFilters({...filters, representativeBranchId: representativeBranch})

  const handleDateFromChange = (validFrom: Date) => setValidFrom(validFrom);

  const handleDateToChange = (validTo: Date) => setValidTo(validTo);

  const handleLeafletTypeChange = (key: string, value: multiselectAttributeDataModel) => setLeafletType(value);

  const correctOrder = ():boolean => isBefore(validFrom, validTo);

  const areAllStatusesSelected = status.length > 0 && statusFilters.length === status.length;

  const areAllLanguagesSelected = languages.length > 0 && selectedLanguages.length === languages.length;

  useEffect(() => {
    let stringifiedTo: string = tryDate2String(validTo);
    let stringifiedFrom: string = tryDate2String(validFrom);

    let newFilters: overviewFilters = {...filters};

    if (validFrom === null && validTo) {
      newFilters.validFrom = null;
      newFilters.validTo = stringifiedTo;
    }
    else if (validTo) {
      if (correctOrder()) {
        newFilters.validFrom = stringifiedFrom;
        newFilters.validTo = stringifiedTo;
      } else {
        newFilters.validFrom = null;
        newFilters.validTo = null;
      }
    } else {
      newFilters.validFrom = stringifiedFrom;
      newFilters.validTo = null;
    }
    if (newFilters.validFrom !== filters.validFrom || newFilters.validTo !== filters.validTo) {
      setFilters(newFilters);
    }
  }, [validFrom]);

  useEffect(() => {
    let stringifiedTo: string = tryDate2String(validTo);
    let stringifiedFrom: string = tryDate2String(validFrom);

    let newFilters: overviewFilters = {...filters};

    if (validTo === null && validFrom) {
      newFilters.validFrom = stringifiedFrom;
      newFilters.validTo = null;
    }
    else if (validFrom) {
      if (correctOrder()) {
        newFilters.validFrom = stringifiedFrom;
        newFilters.validTo = stringifiedTo;
      } else {
        newFilters.validFrom = null;
        newFilters.validTo = null;
      }
    } else {
      newFilters.validFrom = null;
      newFilters.validTo = stringifiedTo;
    }
    if (newFilters.validFrom !== filters.validFrom || newFilters.validTo !== filters.validTo) {
      setFilters(newFilters);
    }
  }, [validTo]);

  const getTranslatedStatuses = (statusFilter: string[]): string => {
    const translatedStatus: string[] = [];
    for (const item in statusFilter) {
      if (statusFilter[item] !== STATUS_ALL) {
        translatedStatus.push(translate({id: leafletStatusLabels[statusFilter[item]]}));
      }
    }
    return translatedStatus.join(', ');
  }

  const sortedLangList = (langList: string[]): filterObject[] => langList
      .map(item => ({
        label: translate({id: 'language.' + item}),
        value: item
      }))
      .sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);

  return (<PaperX>
      <div className='leafletsFiltersContainer'>
          <div className="row">
              <FormControl fullWidth>
                  <InputLabel>{translate({id: 'printMediaOverview.filterStatus'})}</InputLabel>
                    <Select
                        label={translate({id: 'printMediaOverview.filterStatus'})}
                        value={statusFilters}
                        multiple
                        onChange={(e) => handleStatusChange(e.target.value)}
                        renderValue={(statusFilter) => getTranslatedStatuses(statusFilter)}>
                      <MenuItem value={STATUS_ALL}>
                            <ListItemIcon>
                              <Checkbox checked={areAllStatusesSelected} indeterminate={statusFilters.length > 0 && statusFilters.length < status.length}/>
                            </ListItemIcon>
                            {translate({id: leafletStatusLabels[STATUS_ALL]})}
                      </MenuItem>
                      {
                      Object.keys(leafletStatus).map((item) => item === STATUS_ALL ? null :
                          <MenuItem value={item} key={item}>
                            <ListItemIcon>
                              <Checkbox checked={statusFilters.indexOf(leafletStatus[item]) > -1}/>
                            </ListItemIcon>
                            {translate({id: leafletStatusLabels[item]})}
                          </MenuItem>)
                    }
                    </Select>
              </FormControl>
              <CountryBannerSelectorConnected bannerId={filters.bannerId}
                                              onCountryBannerChange={(countryBanner) => handleCountryBannerChange(countryBanner)}
              />
              <ValiditySelector
                  id={1}
                  validityFrom={validFrom || null}
                  validityTo={validTo || null}
                  onValidityFromChange={(id, date) => handleDateFromChange(date)}
                  onValidityToChange={(id, date) => handleDateToChange(date)}
              />
          </div>
          <div className="row">
              <Attribute 
                  id={handyAttributes.leafletType} 
                  data={leafletType}
                  onChange={handleLeafletTypeChange} 
                  multiselect 
                  disableAutoselect={true} 
                />
             <LeafletRepresentativeBranchSelector
                      bannerId={filters.bannerId}
                      onSelect={handleRepresentativeBranchChange}
                      data={filters.representativeBranchId || null}
              />
             <NumberInput
                  valueType={valueTypes.int}
                  value={filters.numberOfPages}
                  onChange={(handleCountryChange, numberOfPages: number) => handleNumberOfPagesChange(numberOfPages)}
                  range={{from: 1}}
                  propIdentifier='numberOfPages'
                  label="printMediaOverview.numberOfPages"
             />
             <FormControl>
                  <InputLabel>{translate({id: 'printMediaOverview.filterLanguage'})}</InputLabel>
                      <Select
                          label={translate({id: 'printMediaOverview.filterLanguage'})}
                          value={selectedLanguages}
                          multiple
                          onChange={(e) => handleLanguageChange(e.target.value)}
                          renderValue={(selectedLang) => sortedLangList(selectedLang).map((item) => item.label).join(", ")}>
                          <MenuItem value={STATUS_ALL}>
                              <ListItemIcon>
                                  <Checkbox checked={areAllLanguagesSelected} indeterminate={selectedLanguages.length > 0 && selectedLanguages.length < languages.length}/>
                              </ListItemIcon>
                              {translate({id: "a.selectAll"})}
                          </MenuItem>
                          {
                            sortedLangList(languages).map((item) =>
                                <MenuItem key={item.value} value={item.value}>
                                  <ListItemIcon>
                                    <Checkbox checked={selectedLanguages.indexOf(item.value) > -1}/>
                                  </ListItemIcon>
                                  {item.label}
                                </MenuItem>)
                          }
                      </Select>
             </FormControl>
             <MediumTypeSelector onMediaTypeChange={handleMediumTypeChange} value={selectedMediumType} mediumTypes={mediumTypes} />
          </div>
      </div>
  </PaperX>);
}