/* eslint-disable react-hooks/exhaustive-deps */
import './DetailsDrawer.scss';
import {FormattedMessage} from 'react-intl';
import {Drawer} from '@mui/material';
import {PaperX} from 'components/PaperX';
import EditFrameValidity from 'modules/Advertisement/AdvertisementBrowser/EditFrameValidity';
import {validity} from 'shared/models/validity.model';

interface DetailsDrawerProps {
    internalFrameId: string,    // needed only for UI to communication between drawer and main view
    isOpen: boolean,
    onChange: (internalFrameId: string, validFrom: string, validTo: string) => void,
    validFrom: string,
    validTo: string
    detailsValidDates: { leafletValidFrom: Date | null; leafletValidTo: Date | null; pageValidFrom: Date | null; pageValidTo: Date | null; };
}


const DetailsDrawer = ({internalFrameId, isOpen, onChange, validFrom, validTo, detailsValidDates}: DetailsDrawerProps) => {

    const handleValidityChange = (validity: validity) => onChange(internalFrameId, validity.validFrom, validity.validTo);

    const leafletValidtyOverride: boolean = !!(detailsValidDates.pageValidFrom || validFrom);
    const pageValidtyOverride: boolean = !!(validFrom);

    return (
        <Drawer className="detailsDrawerRoot"
                anchor="right"
                variant="persistent"
                open={isOpen}
                hideBackdrop
        >
            <div className="_directionRow">
                <PaperX className="_scrollY _fullWidth">
                    <div className="_header">
                        <FormattedMessage id="a.details"/>
                    </div>
                    <div>
                        <FormattedMessage id="b.leafletValidity"/>: {detailsValidDates.leafletValidFrom ? <span
                            className={`_bold ${leafletValidtyOverride && '_disabled'}`}>{detailsValidDates?.leafletValidFrom} - {detailsValidDates.leafletValidTo}</span> :
                        <span className="_bold">-</span>}
                        <br/>
                        <FormattedMessage id="b.pageValidity"/>: {detailsValidDates.pageValidFrom ? <span
                            className={`_bold ${pageValidtyOverride && '_disabled'}`}>{detailsValidDates?.pageValidFrom} - {detailsValidDates.pageValidTo}</span> :
                        <span className="_bold">-</span>}
                    </div>
                    <EditFrameValidity disableEdit={!(internalFrameId)}
                                       disableDelete={!(validFrom && validTo)}
                                       frameValidity={{validFrom: validFrom, validTo: validTo}}
                                       onSave={(frameValidity) => handleValidityChange(frameValidity)}
                                       onDelete={() => onChange(internalFrameId, null, null)}
                                       showCopyTools
                                       isCenteredOnRightSide
                    />
                </PaperX>
            </div>
        </Drawer>
    );
};

export default DetailsDrawer;