import {fabric} from 'fabric';

export const strokeWidthSmall: number = 6;
export const strokeWidthLarge: number = 12;

export const pushEnclosedFramesToFront = (canvas) => {
    let objs: fabric.Canvas = canvas.getObjects();
    if (!objs) return;

    let framesToMoveToFront: fabric.Rect = [];

    // Move items to front
    objs.forEach((obj) => {
        objs.forEach((rect) => {
            if ((obj.left > rect.left) && (obj.top > rect.top) &&
                (obj.left + obj.width < rect.left + rect.width) &&
                (obj.top - obj.height > rect.top - rect.height)) {
                framesToMoveToFront.push(obj);
            }
        });
    })

    let index: number = 0;
    framesToMoveToFront.forEach((frame) => {
        frame.moveTo(objs.length - index);
        index++;
    });
    canvas.renderAll();
}

export const pushEnclosedFramesToBack = (canvas, self) => {
    let objs: fabric.Canvas = canvas.getObjects();

    let framesToMoveToBack: fabric.Rect = [];

    // Move items to back
    objs.forEach((obj) => {
        objs.forEach((rect) => {
            if ((obj.left > rect.left) && (obj.top > rect.top) &&
                (obj.left + obj.width < rect.left + rect.width) &&
                (obj.top - obj.height > rect.top - rect.height)) {
                if (obj !== self) {
                    framesToMoveToBack.push(obj);
                }
            }
        });
    })

    let index: number = 0;
    framesToMoveToBack.forEach((frame) => {
        frame.moveTo(index);
        index++;
    });

    canvas.renderAll();
}


/**
 * Resets all the clip paths of the frames to none.
 * @param fabricCanvas
 * @param fillColor
 */
export const resetAllMasks = (fabricCanvas: fabric.Canvas, fillColor?: string) => {
    let objs: fabric.Canvas = fabricCanvas.getObjects();
    if (!objs) return;

    objs.forEach((frame) => {
        // Set clipPath to empty rect, null won't work for some reason
        frame.clipPath = new fabric.Rect({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            color: "white",
            absolutePositioned: true,
            selectable: false,
            evented: false,
            inverted: true,
        });
        frame.set('strokeWidth', strokeWidthSmall);
        if (fillColor) frame.set('fill', fillColor);
    });
}

/**
 * Adjusts the clip path of all frames to "cut out" the given rect
 */
export const adjustAllClipPaths = (canvas: fabric.Canvas, rect: fabric.Rect) => {
    if (!canvas || !canvas.getObjects()) return;
    let frames: fabric.Canvas = canvas.getObjects();

    frames.forEach((obj) => {
        obj.clipPath = new fabric.Rect({
            left: rect.left+strokeWidthLarge,
            top: rect.top+strokeWidthLarge,
            width: rect.width-strokeWidthLarge,
            height: rect.height-strokeWidthLarge,
            absolutePositioned: true,
            selectable: false,
            evented: false,
            inverted: true,
        })
    })
}