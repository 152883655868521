import './Tooltips.scss';

import {ITooltipParams} from 'ag-grid-community';
import {handyAttributes} from 'shared/handyAttributes';
import {useEffect, useRef, useState} from "react";

interface TooltipParamsWithPosition extends ITooltipParams {
    tooltipPositionTop: number;
}

enum TooltipPosition {
    Up = 'UP',
    Down = 'DOWN'
}

export const MainAttributesTooltip = (props: TooltipParamsWithPosition) => {
    const {tooltipPositionTop, value} = props;
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [direction, setDirection] = useState<TooltipPosition>(TooltipPosition.Down);

    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipHeight: number = tooltipRef.current.offsetHeight;
            const viewportHeight: number = window.innerHeight - 70; // windowHeight minus height of the footer + padding

            if (tooltipPositionTop + tooltipHeight > viewportHeight) {
                setDirection(TooltipPosition.Up);
            } else {
                setDirection(TooltipPosition.Down);
            }
        }
    }, [tooltipPositionTop]);

    return (
        <div ref={tooltipRef} className={`productSearchResultsTooltipRoot ${direction === TooltipPosition.Up ? 'tooltip-upwards' : ''}`.trim()}>
            <ul>
                {value
                    .filter((item) => item.id !== handyAttributes.description)
                    .map((item) => (
                        <li key={item.id}>
                            <span className="attrName">{item.name}:</span>{item.value}
                        </li>
                    ))}
            </ul>
        </div>
    );
}

export const MainAttributesMUITooltipContent = (props) =>
    <ul className="productSearchResultsMUITooltipContentRoot">
        {props.value
            .filter((item) => item.id !== handyAttributes.description)
            .map((item) => <li key={item.id}><span className="attrName">{item.name}:</span>{item.value}</li>)
        }
    </ul>;

export const CategoryTooltip = (props: ITooltipParams) =>
    <div className="productSearchResultsTooltipRoot">
        <span className="categoryName">{props.value}</span>
    </div>;